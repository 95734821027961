import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import { Form, Button } from 'react-bootstrap';
import { handleFileUpload } from './ApiService';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

function FileUploader() {

  const { isAuthenticated } = useAuth();
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { state: { showLogoutMessage: true } });
      return;
    }
  }, []);
  

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleUpload = async () => {
    if (!selectedFiles) {
      alert('Please select a file first!');
      return;
    } else {
      try{
        setIsUploading(true);
        const data = await handleFileUpload(selectedFiles);
        if (data.success) {
          alert('Files uploaded successfully!');
        } else {
          alert('Files upload failed: ' + data.message);
        }
        setIsUploading(false);
      }catch(error){
        setIsUploading(false);
        alert('Files upload failed: ' + error.message);
      }
    }

    // Here you would typically create a FormData object and send it to the server
    // using fetch or axios. For example:
    /*
    const formData = new FormData();
    formData.append('file', selectedFile);

    fetch('YOUR_UPLOAD_ENDPOINT', {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
    })
    .catch(err => {
      console.error(err);
    });
    */
  };

  return (
    <div>
      <NavBar/>
      <div className='m-2'>
      <h1>File Uploader</h1>
      <Form onSubmit={handleUpload}>
      <Form.Group controlId="fileField" className="mb-3">
        <Form.Label>Select Files</Form.Label>
        <Form.Control type="file" multiple onChange={handleFileChange} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Upload
      </Button>
      </Form>
      {isUploading && <p>Uploading...</p>}
      </div>
    </div>
  );
}

export default FileUploader;
