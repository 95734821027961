// src/LoginPage.js
import React from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import microsoftLogo from "./microsoft_login_button.svg";
import GoogleButton from 'react-google-button'
import medpointLogo from './medpoint-logo.png'

const LoginPage = () => {
  const handleGoogleLogin = async () => {
    // TODO: get this from endpoint
    window.location.href = window.googleLoginUrl;
  };

  const handleMicrosoftLogin = async () => {
    // TODO: get this from endpoint
    window.location.href = window.microsoftLoginUrl;
  };
  const messages = window.djangoMessages || [];

  return (
    <div className="login-container w-100">
      <Container className="mt-5 w-50">
      <Row className="justify-content-md-center mb-5">
          <Col xs={12} className="text-center">
              <img
                alt="medpoint"
                src={medpointLogo}
                width="auto"
                height="auto"
                className="align-top"
              />
            <h1 className="font-weight-bold" >Match Up</h1>
          </Col>
        </Row>
        <Row className="justify-content-md-center bg-light p-4 rounded">
          <Col xs={12} className="text-center">
            <h2> Login</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center bg-light p-2 rounded">
          <Col xs={12} md={6}>
            <Form action="/login-action/" method="POST" encType="multipart/form-data">
              <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  name={"username"}
                  type="text"
                  placeholder="Enter Username"
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name={"password"}
                  type="password"
                  placeholder="Password"
                />
              </Form.Group>
              {messages.length > 0 && messages.map((message, index) => (
                <div key={index} className="message">
                  {message.level}: {message.message}
                </div>
              ))}
              <Button variant="primary" className="w-100 my-2" type="submit">
                Login
              </Button>
              <Row className="mt-3">
                <Col xs={5}>
                  <hr />
                </Col>
                <Col xs={2}>
                  <p className="text-center">OR</p>
                </Col>
                <Col xs={5}>
                  <hr />
                </Col>
              </Row>
              {/* Google and Microsoft login options */}
              <GoogleButton
                type="light" // can be light or dark
                className="w-100 mb-2"
                onClick={handleGoogleLogin}
              />
              <Button
                variant="dark"
                className="w-100"
                onClick={handleMicrosoftLogin}
              >
                <img
                  src={microsoftLogo}
                  alt="Login with Microsoft"
                  style={{ width: "75%" }}
                />
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
