import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
// import { AuthProvider } from "./AuthContext";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import FileUploader from "./FileUpload";

function App() {
  return (
    // <AuthProvider> //Removed for now since we are authenticating on the backend
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<HomePage/>} />
          <Route path="/upload" element={<FileUploader/>} />
        </Routes>
      </Router>
    // </AuthProvider>
  );
}

export default App;