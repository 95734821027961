import React, {useState, useEffect, Fragment} from "react";
import {Form, Button, Col, Row, Spinner} from "react-bootstrap";
import NavBar from "./NavBar";
import {processQuery, getSearchTags} from "./ApiService";
import Select from "react-select";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Tag} from "primereact/tag";
import {Accordion, AccordionTab} from "primereact/accordion";
import {TabView, TabPanel} from "primereact/tabview";
import {Card} from "primereact/card";

function HomePage() {
    const [contractorResults, setContractorResults] = useState([]); //results of the search query
    const [isLoading, setIsLoading] = useState(false); //loading spinner for search query
    const [visible, setVisible] = useState(false); //dialog visibility for contractor details
    const [selectedContractor, setSelectedContractor] = useState([]); //selected contractor for dialog
    const [selectedSearchTags, setSelectedSearchTags] = useState([]); //tracks the form input for tags
    const [selectedTags, setSelectedTags] = useState([]); //used to filter matching tags in the table
    const [tagOptions, setTagOptions] = useState([]); //populates the tag dropdown with options from the backend

    useEffect(() => {
        getSearchTags().then((response) => {
            if (response.success) {
                setTagOptions(response?.data?.tags || []);
            } else {
                alert("Failed to fetch tags: " + response.message);
            }
        });
    }, []);

    const onRowSelect = (event) => {
        setSelectedContractor(event.data);
        setVisible(true);
    };

    const handleSearchTagChange = (selectedOptions) => {
        setSelectedSearchTags(selectedOptions);
    };

    const openResumeInNewTab = (path) => {
        //add path to current url
        const url = window.location.origin + path;
        window.open(url, '_blank');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!event.target[0].value && selectedSearchTags.length === 0) {
            alert("Please input search text or tags");
            return;
        } else {
            setIsLoading(true);
            try {
                const selectedTagValues =
                    selectedSearchTags.length > 0
                        ? selectedSearchTags.map((tag) => tag.value)
                        : []; //get the values of the selected tags
                const response = await processQuery(
                    event.target[0].value,
                    selectedTagValues
                );
                if (response.success) {
                    setSelectedTags(selectedSearchTags);
                    setContractorResults(response?.data);
                } else {
                    alert(
                        "Search failed due to an internal server error: " + response.message
                    );
                }
                setIsLoading(false);
            } catch (error) {
                alert("Internal error" + error);
                setIsLoading(false);
            }
        }
    };

    const generateTagOnlyList = (tagsList, severity = "danger") => {
        const randomKey = Math.floor(Math.random() * 100) * 100; //generates a 4 digit number with 00 at the end
        return <div>{tagsList.map((tag, index) => <Tag className="m-1" value={tag} key={(randomKey + index)}
                                                       severity={severity}></Tag>)}</div>;
    }

    const generateTagList = (tagsList, severity = "danger") => {
        return (
            tagsList.map((group, index) => {
                return (
                    <div key={index}>
                        <span className="fw-bold">{group.group_name}:</span>
                        {group.tags.map((tag, i) => {
                            return (
                                <Tag
                                    className="m-1"
                                    value={tag}
                                    key={`${index}-${i}`}
                                    severity={severity}
                                    rounded
                                />
                            );
                        })}
                    </div>
                );
            })
        );
    };

    const locationTemplate = (contractor) => {
        const airtableData = contractor.airtable_data;
        const location =
            (airtableData?.City || "") +
            " " +
            (airtableData?.State || "") +
            " " +
            (airtableData?.Country || "");
        return location.trim() ? (
            <p>{location}</p>
        ) : (
            <p>
                <em>Not available</em>
            </p>
        );
    };

    const tagListTemplate = (contractor) => {
        const tagsDataList = contractor?.tags || [];
        return generateTagList(tagsDataList);
    };

    const filteredTagListTemplate = (contractor) => {
        const tagsDataList = contractor?.tags || [];
        let tagsList = [];
        if (tagsDataList.length > 0) {
            tagsList = tagsDataList.map((tagData) => tagData.tags).flat();
        }
        //filter tags to see if they match any tags in selectedTags
        const matchedTags = tagsList.filter((tag) =>
            selectedTags.some((selectedTag) => selectedTag.label === tag)
        );
        return generateTagOnlyList(matchedTags);
    };

    const resultTypeTemplate = (contractor) => {
        return (
            <div>
                {contractor?.airtable_data ? (
                    <i className="pi pi-table h4 m-1"></i>
                ) : (
                    ""
                )}
                {contractor.type === "resume" ? (
                    <i className="pi pi-file h4 m-1"></i>
                ) : (
                    ""
                )}
            </div>
        );
    };

    const summaryHeaderTemplate = (contractor) => {
        return (
            <div className="d-flex w-100">
                <h2 className="w-100">
                    {contractor?.airtable_data?.Contractor_Name ?? "Contractor Details"}
                </h2>
                <Button
                    className="text-nowrap"
                    disabled={contractor.type !== "resume"}
                    onClick={() => openResumeInNewTab(selectedContractor?.file_path)}
                >
                    Resume
                    <i className="pi pi-external-link ms-2"></i>
                </Button>
            </div>
        );
    };

    const airtableDataFieldTemplate = (airtableData, field) => {
        const airtableValue = airtableData[field];
        if (typeof airtableData[field] === "object") {
            //needed for null values
            if (airtableValue.length > 1) {
                // all the airtable fields are arrays
                return <div className="mt-0">{generateTagOnlyList(airtableValue)}</div>;
            }
        }
        if (typeof airtableValue[0] === "boolean") {
            return <p className="mt-0">{airtableValue[0] ? "True" : "False"}</p>;
        } else {
            return <p className="mt-0">{airtableData[field]}</p>;
        }
    };

    const airtableDataTemplate = (contractor) => {
        const airtableData = contractor?.airtable_data;
        if (!airtableData) return <p>No Airtable data available</p>;
        const dataKeys = Object.keys(airtableData);

        return dataKeys.map((key, i) => {
            return (
                <div key={i}>
                    <span className="fw-bold">{key.replace(/_/g, " ")}:</span> {typeof(airtableData[key]) === "object"  ? airtableData[key].join(", "): airtableData[key]}
                </div>
            )
        })

        // <DataTable
        //     showGridlines={true}
        //     value={[airtableData]}
        //     tableStyle={{minWidth: "50rem"}}
        // >
        //     {dataKeys.map((data, i) => (
        //         <Column
        //             className="align-top"
        //             key={data}
        //             field={data} //field is the key in the object
        //             header={data.replace(/_/g, " ")} //replaces all underscores with spaces
        //             headerStyle={{
        //                 fontWeight: "bold",
        //                 backgroundColor: "#f2f2f2",
        //                 borderBottom: "1px solid gray",
        //                 verticalAlign: "bottom",
        //             }}
        //             body={airtableDataFieldTemplate(airtableData, data)}
        //         />
        //     ))}
        // </DataTable>
    };

    const resumeDataTemplate = (contractor) => {
        const [activeIndex, setActiveIndex] = useState([0, 1, 2, 3]);
        const resumeData = contractor?.resume_data;
        if (!resumeData) return <p>No Resume Data Available</p>;

        return <Fragment>
            <Row>
                <Col sm={4}>
                    <h3>Contact Info</h3>
                    <p className="mb-1">
                        {"Email: " +
                            (selectedContractor?.resume_data?.contact_info?.email ||
                                "Not available")}
                    </p>
                    <p className="mb-1">
                        {"Phone: " +
                            (selectedContractor?.resume_data?.contact_info?.phone ||
                                "Not available")}
                    </p>
                    <p className="mb-3">
                        {"Address: " +
                            (selectedContractor?.resume_data?.contact_info?.address ||
                                "Not available")}
                    </p>
                </Col>
                <Col sm={8}>
                    <h3>Summary</h3>
                    <p className="mb-3">
                        {selectedContractor?.resume_data?.short_summary || "Not available"}
                    </p>
                </Col>
            </Row>
            <Row>
                <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <AccordionTab header="Work Experience">
                        {selectedContractor?.resume_data?.work_experience?.map(
                            (workData, index) => {
                                return <Card
                                    title={workData.position}
                                    subTitle={
                                        <div>
                                            {workData.company} <br></br> {workData.duration}
                                        </div>
                                    }
                                    className="w-100rem mb-2"
                                >
                                    <p className="m-0">{workData.duties}</p>
                                </Card>;
                            }
                        )}
                    </AccordionTab>
                    <AccordionTab header="Education">
                        <div>
                            {selectedContractor?.resume_data?.education?.map(
                                (education, index) => {
                                    return <Card
                                        title={education.degree}
                                        subTitle={
                                            <div>
                                                {education.school} <br></br> {education.year}
                                            </div>
                                        }
                                        className="w-100rem mb-2"
                                    ></Card>;
                                }
                            )}
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Skills">
                        <ul>
                            {selectedContractor?.resume_data?.skills?.map((skill, index) => {
                                return <li key={index}>{skill}</li>;
                            })}
                        </ul>
                    </AccordionTab>
                    <AccordionTab header="Certifications">
                        <ul>
                            {selectedContractor?.resume_data?.certifications?.map(
                                (certification, index) => {
                                    return <li key={index}>{certification}</li>;
                                }
                            )}
                        </ul>
                    </AccordionTab>
                </Accordion>
            </Row>
        </Fragment>;
    };

    const summaryTableTemplate = (contractor) => {
        if (contractor.type === "resume") {
            return contractor.resume_data?.short_summary;
        } else {
            return (
                <div>
                    <Row>
                        <Col>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <p className="m-0 me-2 fw-bold">Status:</p>
                                <Tag className="gray-tag" value={contractor.airtable_data?.Contractor_Status}
                                     rounded></Tag>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <p className="m-0 me-2 fw-bold">ISO Certifications:</p>
                                <Tag className="gray-tag"
                                     value={
                                         contractor.airtable_data["ISO_Standard_(Certified)"]
                                             ?.length ?? 0
                                     } rounded
                                ></Tag>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <p className="m-0 me-2 fw-bold">
                                    Other Quality Standard Certifications:
                                </p>
                                <Tag className="gray-tag"
                                     value={
                                         contractor.airtable_data[
                                             "Other_Quality_Standard_(Certification)"
                                             ]?.length ?? 0
                                     } rounded
                                ></Tag>
                            </div>
                        </Col>
                        <Col>
                            <p>
                                <span className="fw-bold">Subject Matter Expert:</span>{" "}
                                {contractor.airtable_data["Subject_Matter_Expert_(SME)"]}
                            </p>
                            <p>
                                <span className="fw-bold">Other Certifications:</span>{" "}
                                {contractor.airtable_data["Other_Certifications"]}
                            </p>
                        </Col>
                    </Row>
                </div>
            );
        }
    };

    return (
        <div className="HomePage bg-light h-100">
            <NavBar/>
            <Form className="w-100 py-3 bg-dark" onSubmit={handleSubmit}>
                <Row className="mx-1">
                    <Form.Group
                        as={Col}
                        className="col-6 d-flex text-white my-2 ps-5"
                        controlId="formTextArea"
                    >
                        <Form.Label className="me-3">Search</Form.Label>
                        <Form.Control
                            as="input"
                            placeholder={"Looking for a candidate with..."}
                        />
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        className="col-5 d-flex text-white my-2"
                        controlId="formTags"
                    >
                        <Form.Label>Tags</Form.Label>
                        <Select
                            defaultValue={[]}
                            onChange={handleSearchTagChange}
                            isMulti
                            closeMenuOnSelect={false}
                            name="tags"
                            options={tagOptions}
                            className="basic-multi-select text-dark ms-3 w-100 h-100"
                            // classNamePrefix="select"
                        />
                    </Form.Group>

                    <Col className="col-1 d-flex my-2">
                        <Button
                            className="align-self-en"
                            variant="primary"
                            type="submit"
                            disabled={isLoading}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
            {/* <Toast ref={toast} /> */}
            <Dialog
                header={summaryHeaderTemplate(selectedContractor)}
                visible={visible}
                style={{width: "75vw"}}
                onHide={() => setVisible(false)}
            >
                <h3 className="mt-1 mb-2">Candidate Information</h3>
                <TabView activeIndex={selectedContractor.type == "resume" ? 0 : 1}>
                    <TabPanel
                        header="Resume"
                        leftIcon={"pi pi-file mx-1"}
                        disabled={selectedContractor.type != "resume"}
                    >
                        {resumeDataTemplate(selectedContractor)}
                    </TabPanel>
                    <TabPanel header="Airtable" leftIcon={"pi pi-table mx-1"}>
                        {airtableDataTemplate(selectedContractor)}
                    </TabPanel>
                </TabView>
                <hr className="mb-2"></hr>
                <Accordion>
                    <AccordionTab header={"Tags"}>
                        {tagListTemplate(selectedContractor)}
                    </AccordionTab>
                </Accordion>
            </Dialog>
            <div className="p-5">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>Candidate Search</h2>
                    {contractorResults.length ? <h5>Found {contractorResults.length} records</h5>:null}
                </div>
                <div className="d-flex p-2">
                    <DataTable
                        hidden={isLoading}
                        value={contractorResults}
                        stripedRows
                        selectionMode="single"
                        dataKey="id"
                        onRowSelect={onRowSelect}
                        metaKeySelection={false}
                        tableStyle={{minWidth: "50rem"}}
                        emptyMessage={"No results found"}
                    >
                        <Column style={{minWidth: "15rem"}}
                                field="airtable_data.Contractor_Name"
                                header="Name"
                        ></Column>
                        <Column header="Summary" body={summaryTableTemplate}></Column>
                        <Column style={{minWidth: "15rem"}} header="Location" body={locationTemplate}></Column>
                        <Column
                            field="type"
                            header="Data Sources"
                            className="align-end"
                            body={resultTypeTemplate}
                        ></Column>
                        <Column
                            field="tags"
                            header="Matched Tags"
                            body={filteredTagListTemplate}
                        ></Column>
                    </DataTable>
                    <Spinner
                        className="m-2"
                        animation="border"
                        role="status"
                        hidden={!isLoading}
                    >
                        <span className="visually-hidden">Loading</span>
                    </Spinner>
                    <p as={Col} className="m-2" hidden={!isLoading}>
                        Processing your request...
                    </p>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
