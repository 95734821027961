// Description: This file contains the functions that make requests to the backend API.
// Return object pattern:
// {
//     success: boolean,
//     message: string, (optional if success is true. Typically an error message if there is one)
//     data: any (optional. The data returned by the API)
// }
import {ContractorAPIResponse, TagListAPIResponse} from "./TempData";

const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const login = async (username, password) => {
  try {
    const response = await fetch("/login-action/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    //verify response status
    if (response.status === 200) {
      console.log("Login successful");
      //AMY TODO: Set the session id in a cookie? or will this automatically be done by the browser?
      return { success: true }; //redirect to home page will be handled in the LoginPage component
    } else {
      console.error("Login failed:", response.statusText);
      return { success: false, message: response.statusText };
    }
  } catch (error) {
    console.error("Error logging in:", error);
    return { success: false, message: error.message };
  }
};

export const loginWithGoogle = async () => {
  try {
    const response = await fetch("/login-action/", {
      //TODO: Add the correct endpoint for Google auth
      method: "POST",
    });
    //verify response status
    if (response.status === 200) {
      console.log("Login successful");
      //AMY TODO: Set the session id in a cookie? or will this automatically be done by the browser?
      return { success: true };
    } else {
      console.error("Login failed:", response.statusText);
      return { success: false, message: response.statusText };
    }
  } catch (error) {
    console.error("Error logging in:", error);
    return { success: false, message: error.message };
  }
};

export const loginWithMicrosoft = async () => {
  try {
    const response = await fetch("/login-action/", {
      //TODO: Add the correct endpoint for Microsoft auth
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    //verify response status
    if (response.status === 200) {
      console.log("Login successful");
      //AMY TODO: Set the session id in a cookie? or will this automatically be done by the browser?
      return { success: true };
    } else {
      console.error("Login failed:", response.statusText);
      return { success: false, message: response.statusText };
    }
  } catch (error) {
    console.error("Error logging in:", error);
    return { success: false, message: error.message };
  }
};

export const handleFileUpload = async (files) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    const response = await fetch("/upload-file/", {
      //TODO: Add the correct endpoint for file upload
      method: "POST",
      body: formData,
    });
    //const response = { status: 200 }; //AMY TODO: Remove this line once the file upload endpoint is implemented
    if (response.status === 200) {
      //TODO: Check the correct status code for successful file upload
      console.log("File uploaded successfully");
      return { success: true };
    } else {
      console.error("File upload failed:", response.statusText);
      return { success: false, message: response.statusText };
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    return { success: false, message: error.message };
  }
};

export const processQuery = async (text, tagsList) => {
  try {
    const cookie = getCookie("csrftoken");
    if(!cookie){
      console.error("CSRF token not found in cookies");
      return { success: false, message: "CSRF token not found in cookies" };
    }
    const response = await fetch("/api/process-query/", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie,
      },
      body: JSON.stringify({ query: text, tags: tagsList}),
    });
    if (response.status === 200) {
      const data = await response.json();
      // console.log("DATA:", data);
      return { success: true, data: data.response };
    } else {
      return { success: false, message: response.statusText };
    }
  } catch (error) {
    console.error("Error processing query:", error);
    return { success: false, message: error.message };
  }
};

export const getSearchTags = async () => {
  try {
    const cookie = getCookie("csrftoken");
    if(!cookie){
      console.error("CSRF token not found in cookies");
      return { success: false, message: "CSRF token not found in cookies" };
    }
    const response = await fetch("/api/config/", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie,
      }
    });
    if (response.status === 200) {
      const data = await response.json();
      //console.log("DATA:", data);
      return { success: true, data: data };
    } else {
      return { success: false, message: response.statusText };
    }
  } catch (error) {
    console.error("Error processing query:", error);
    return { success: false, message: error.message };
  }
};

export const getSearchTagsTEMP = async () => {
  try {
    return { success: true, data: TagListAPIResponse };
  } catch (error) {
    console.error("Error processing query:", error);
    return { success: false, message: error.message };
  }
};

export const processQueryTEMP = async (text, tagsList) => {
  try {
    return { success: ContractorAPIResponse.success, data: ContractorAPIResponse.response };
  } catch (error) {
    console.error("Error processing query:", error);
    return { success: false, message: error.message };
  }
};