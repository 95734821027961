import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  //returns sessionId from cookie or null if not found
  const getSessionId = () => {
    let sessionId = null;
    console.log("checking cookies for sessionid...")
    console.log(document.cookie)
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        console.log("checking cookie: ", cookie)
        if (cookie.substring(0, 10) === "sessionid=") {
          //AMY TODO: Make sure this substring is working
          console.log("Cookie found: ", cookie.substring(10));
          sessionId = decodeURIComponent(cookie.substring(10));
          console.log("Session id: ", sessionId)
          break;
        }
      }
    }
    return sessionId;
  };

  const verifySession = async () => {
    const sessionId = getSessionId();
    if (!sessionId) {
      console.log("PROBLEM: missing session id");
      setIsAuthenticated(false);
    } else {
      console.log("session id verified");
      setIsAuthenticated(true);
    }
  };

  // Ask Georg if we need an endpoint to verify the session
  // georg says, yes! but not the session id, i would provide your login and name and some urls
  //     try {
  //       const response = await fetch("YOUR_BACKEND_ENDPOINT/api/session/verify", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ sessionId }),
  //       });

  //       const data = await response.json();
  //       if (data.isAuthenticated) {
  //         setIsAuthenticated(true);
  //       } else {
  //         setIsAuthenticated(false);
  //         //AMY CHECK THIS: do we need to remove the session id from the cookie?
  //       }
  //     } catch (error) {
  //       console.error("Error verifying session:", error);
  //       setIsAuthenticated(false);
  //     }
  //   };

  // Call verifySession when the component mounts
  useEffect(() => {
    verifySession();
  }, []);

  const login = () => setIsAuthenticated(true);

  const logout = () => setIsAuthenticated(false);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;