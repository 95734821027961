import React from "react";
import { Navbar, Nav, Container  } from "react-bootstrap";
import { Link } from "react-router-dom";
import medpointLogo from "./medpoint-logo.png";


const NavBar = () => {
  return (
    <div>
    <div className="m-5 bg-dark"></div>
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="">
          <img
            alt="medpoint"
            src={medpointLogo}
            width="auto"
            height="30"
            className="d-inline-block align-top bg-light p-1 rounded mx-1"
          />{' '}
          Match Up
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Link to="/home" className="nav-link">
              Search
            </Link>
            <a href="/admin/filer/folder/" target="_blank" className="nav-link"> 
              Upload
            </a>
            <a href="/logout/" className="nav-link">
              Logout
            </a>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
};

export default NavBar;